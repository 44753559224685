/** @format */

import React from "react";

export default function VideoSection() {
  return (
    <div className='container-fluid section-padding'>
      <div className='row g-0'>
        <div className='col-md-12 m-auto'>
          <div className='video-cover-bg m-auto'>
            <div className='video-text' data-aos='fade-right'>
              <h1>The Game</h1>
              <p>
                There are numerous ways to play The Insurrection, which can be
                challenging, but the heart of the game is in the campaign mode.
                After an extended prologue that dominates the Roman Empire, the
                campaign allows you to choose from nine starting factions and
                attempt to take over the world, to restore peace and justice.
              </p>
              <p>
                The most preferred point of faction is the Venezuela city. In
                the beginning each player is given two immediate acquaintances,
                like Thyra and Alanis for Olympias. Players have to gather
                different resources like, infantry, carpentry, barracks, hotels,
                schools, church, housing and castles. These resources are used
                to build strong economies to host and support new population.
                The maximum number of individuals that a player can have in any
                economy is 300.
              </p>

              <div>
                <a
                  className='links d-flex'
                  href='https://insurrection-app.gitbook.io/the-insurrection/'
                  target='_blank'
                  rel='noreferrer'>
                  Document
                  <img src='assets/vector-right.svg' alt='' className='mx-2' />
                </a>
              </div>
              <div className='col-sm-6 mt-4'>
                <a href='https://blocksafu.com/audit/0xe9EEA2724Fa380Ae3CEecd7902065a90313BBc96' target='_blank' rel='noopener noreferrer'>
                  <img src='assets/blocksafu.svg' className='w-75 ' alt='' />{" "}
                  {/* <strong className='safu2'>BlockSafu</strong> */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
