/** @format */

import React from "react";
import Carousel from "react-multi-carousel";

const ScreenShots = () => {
  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
      <div
        className='arrow-box mt-4'
        style={{
          textAlign: "center",
        }}>
        <button onClick={previous}>
          {" "}
          <img src='assets/arrow1.png' alt='' />
        </button>
        <button onClick={next}>
          {" "}
          <img src='assets/arrow.png' alt='' className='mx-3' />
        </button>
      </div>
    );
  };

  return (
    <div className='container'>
      <div className='ss-text mb-5' data-aos='fade-right'>
        <h1>Game Screen Shots</h1>
      </div>
      <div
        style={{
          position: "relative",
        }}>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlaySpeed={3000}
          centerMode={false}
          className='slide-container-img'
          containerClass='container-padding-bottom'
          customButtonGroup={<CustomButtonGroupAsArrows />}
          dotListClass=''
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=''
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=''
          slidesToSlide={1}
          swipeable>
          <img
           src={process.env.REACT_APP_S3_URL + "/7CnEdMB.png"}
            alt='screen'
          />
          <img
           src={process.env.REACT_APP_S3_URL + "/XTS07MV.png"}
            alt='screen'
          />
          <img
           src={process.env.REACT_APP_S3_URL + "/TFr2QSX.png"}
            alt='screen'
          />
          <img
           src={process.env.REACT_APP_S3_URL + "/qDXN6g5.png"}
            alt='screen'
          />
          <img
           src={process.env.REACT_APP_S3_URL + "/qADcYui.jpg"}
            alt='screen'
          />
          <img
           src={process.env.REACT_APP_S3_URL + "/DZcEZpp.jpg"}
            alt='screen'
          />
          <img
           src={process.env.REACT_APP_S3_URL + "/BCEzWUC.jpg"}
            alt='screen'
          />
          <img
           src={process.env.REACT_APP_S3_URL + "/AJT6YBP.jpg"}
            alt='screen'
          />
          <img
           src={process.env.REACT_APP_S3_URL + "/9VANCT3.jpg"}
            alt='screen'
          />
          <img
           src={process.env.REACT_APP_S3_URL + "/UD1XNBY.jpg"}
            alt='screen'
          />
          <img
           src={process.env.REACT_APP_S3_URL + "/MWLxl8o.jpg"}
            alt='screen'
          />
             <img
           src={process.env.REACT_APP_S3_URL + "/bHgGBlI.jpg"}
            alt='screen'
          />
             <img
           src={process.env.REACT_APP_S3_URL + "/x9R8cw7.jpg"}
            alt='screen'
          />
        </Carousel>
      </div>
    </div>
  );
};

export default ScreenShots;
