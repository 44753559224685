/** @format */

import React from "react";

const Partners = () => {
  return (
    <div className='py-16 partners'>
      <div className='container m-auto px-6 space-y-8 md:px-12 lg:px-56'>
        <div className=' '>
          <h1 className='text-center m-auto'>Partners</h1>
        </div>
        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4'>
          <div className='p-4'>
            <img src='assets/partner/Group-48096492.png' className='' alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/newp-logo-1.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/Group-48096488.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/newp-logo-2-custom.png' alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/Group-48096493.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/Group-48096496.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/Group-48096571.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/Group-43.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/newp-logo-3.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/Group-42.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/Group-48096574.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/newp-logo-4.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/newp-maven.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/Group-48096490.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/Group-41.png'  alt='' />
          </div>
          <div className='p-4'>
            <img src='assets/partner/Group-40.png'  alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
