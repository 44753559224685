/** @format */

import React from "react";
function Header() {
  return (
    <header>
      <nav className='navbar navbar-expand-lg navbar-light '>
        <div className='container-fluid'>
          <a className='navbar-brand' href='/'>
            <img src='assets/logo-hr.png' alt='' width={150} />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav m-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <a className='nav-link active' aria-current='page' href='/'>
                  Home
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='https://insurrection-app.gitbook.io/the-insurrection/'
                  target='_blank'
                  rel='noreferrer'>
                  <strong>Document</strong>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://blocksafu.com/audit/0xe9EEA2724Fa380Ae3CEecd7902065a90313BBc96" target="_blank" rel="noreferrer" >
                  Audit
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#in-progress">
                  Stake
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#in-progress">
                  Vote
                </a>
              </li> */}
              <li className='nav-item'>
                <a className='nav-link' href='#GamePlay'>
                  Gameplay
                </a>
              </li>

              {/* <li class="nav-item">
                <a class="nav-link" href="#RoadMap">
                  roadmap
                </a>
              </li> */}
            </ul>
            <a
              href='https://twitter.com/SRPGLive/status/1753424671129973031'
              target='_blank'
              rel='noopener noreferrer'>
              <button className='header-btn'>start</button>
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
