/** @format */

import React from "react";
import CountDown from "../utils/Countdown";

export default function Hero() {
  return (
    <>
      <div
        className='container-fluid hero-background overflow-hidden'
        id='hero'>
        <div className='row'>
          <div className='col-md-12 relative'>
            <h3 className='text-[#00fac8] text-center my-3 text-3xl'>
              Earn <strong>$50</strong> Daily
            </h3>
            <div className='flex gap-3 justify-center items-center flex-wrap absolute left-1/2  top-20 opacity-65 mix-blend-luminosity transform -translate-x-1/2 -translate-y-1/2 mt-4'>
              <a
                href='https://www.dextools.io/app/en/bnb/pair-explorer/0x36e795d5c5d9b4df85921865311553a537187d14?t=1707051430651'
                target='_blank'
                title='Dextools'
                rel='noopener noreferrer'>
                <img src='assets/dextools.svg' className='w-16' alt='' />
              </a>
              <a
                href='https://pancakeswap.finance/swap?outputCurrency=0xe9EEA2724Fa380Ae3CEecd7902065a90313BBc96'
                target='_blank'
                title='PancakeSwap'
                rel='noopener noreferrer'>
                <img src='assets/cake-token.svg' className='w-20' alt='' />
              </a>
              <a
                href='https://www.dexview.com/bsc/0xe9EEA2724Fa380Ae3CEecd7902065a90313BBc96'
                target='_blank'
                title='Dexview'
                rel='noopener noreferrer'>
                <img src='assets/Dexview.png' className='w-20' alt='' />
              </a>
            </div>
            <div className='hero-center-img flex-col' data-aos='fade-up'>
              <img
                src='/assets/logo-hr.png'
                alt=''
                width={500}
                className='res-img'
              />
              {/* <CountDown /> */}
              <a
                href='https://pancakeswap.finance/swap?outputCurrency=0xe9EEA2724Fa380Ae3CEecd7902065a90313BBc96'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <button className='mt-4'>Buy $SRPG</button>
              </a>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='arrow-down-bg text-center flex justify-center'>
                <img src='assets/ellipse7.svg' alt='' className='circle' />
                <a href='#GamePlay'>
                  {" "}
                  <img src='assets/vector.svg' alt='' className='pt-5 mt-2' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
