/** @format */

import React from "react";

const Story = () => {
  return (
    <div className='container-fluid story pt-20'>
      <div className='container'>
        <div className='title'>
          <p className='gr'>USER-GENERATED WORLDS —</p>
          <p className='wr'>— WRITE YOUR OWN STORY</p>
        </div>
        <p className='text-white text-xl text-center '>
          Evolve your overall experience by creating «Assets» on lands parcels
        </p>

        <div className='row py-20'>
          <div className='col-md-4'>
            <div className='text-center steps'>
              <h1>01</h1>
              <h5>
                PLAYERS CAN BUY MORE, A DIGITAL PIECE OF JUNGLE LAND, ON INSURRECTION
              </h5>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='text-center steps'>
              <h1>02</h1>
              <h5>
                POPULATE YOUR LAND WITH GAME MODES <br />
                AND ASSETS TO CREATE IN-GAME EXPERIENCES
              </h5>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='text-center steps'>
              <h1>03</h1>
              <h5>PARTICIPATE IN THESE EXPERIENCES CREATED BY FELLOW GAMERS</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
