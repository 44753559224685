/** @format */

import React from "react";

export default function Tokens() {
  return (
    <div className='container tokens' id='Tokkens'>
      <div className='row'>
        <div className='tokkens-heading' data-aos='fade-up'>
          <h1>$SRPG Token</h1>
          <p>
            The $SRPG token is the game currency. These are the gold coins that
            you will be able <br /> to use in all areas of the game. Itis
            treated as a national currency in all continents..
          </p>
        </div>
        <div className='col-md-6'>
          <div
            className='tokens-bg-img position-relative'
            data-aos='fade-right'>
            <div>
              <h1>Governance & Staking</h1>
              <ul className='tokken-list-items'>
                <li>
                  <img src='assets/ellipse 7.svg' alt='' />
                  <span>Player community involvment</span>
                </li>
                <li>
                  <img src='assets/ellipse 7.svg' alt='' />
                  <span>Decentralization of game economy</span>
                </li>
                <li>
                  <img src='assets/ellipse 7.svg' alt='' />
                  <span>Income economics on the Inssurection</span>
                </li>
                <li>
                  <img src='assets/ellipse 7.svg' alt='' />
                  <span>Transfer of authority to the masses</span>
                </li>
                <li>
                  <img src='assets/ellipse 7.svg' alt='' />
                  <span>Top rewards on investments</span>
                </li>
                <img src='assets/Coin.svg' alt='coin' className='coin hidden md:flex' />
              </ul>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='tokens-bg-img' data-aos='fade-up'>
            <div>
              <h1>$SRPG</h1>
              <ul className='tokken-list-items'>
                <li>
                  <img src='assets/ellipse 7.svg' alt='' />
                  <span>Monetization of in-game assets</span>
                </li>
                <li>
                  <img src='assets/ellipse 7.svg' alt='' />
                  <span>Validity of authority in governance matters</span>
                </li>
                <li>
                  <img src='assets/ellipse 7.svg' alt='' />
                  <span>Capital gain value of the token</span>
                </li>
                <li>
                  <img src='assets/ellipse 7.svg' alt='' />
                  <span>Earned during gameplay</span>
                </li>
                <li>
                  <img src='assets/ellipse 7.svg' alt='' />
                  <span>Medium of exchange</span>
                </li>

                <img src='assets/stone.svg' alt='coin' className='coin1 hidden md:flex' />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12' data-aos='fade-down'>
          <img
            src='assets/Charts.svg'
            alt=''
            className='w-75 d-block m-auto res-img'
          />
        </div>
      </div>
    </div>
  );
}
