/** @format */

import React from "react";
import Faq from "react-faq-component";

const data = {
  //   title: "FAQ (How it works)",
  rows: [
    {
      title: "How to get SRPG token?",
      content: `SRPG tokens can be bought from the pancakeswap and other affiliated DEXs mentioned on our website.`,
    },
    {
      title: "What happens to my game collectibles after game logout?",
      content: `After logout the in-game assets can be used as collectibles and traded on online marketplaces.`,
    },
    {
      title: "How can I advance in the game?",
      content: `Players can only advance in the game by completing missions and gathering SRPG tokens. A detailed game walktrhough is available in our gitbook too?`,
    },
    {
      title: "The game is too hard for me?",
      content: `In case, you find the game difficult you can turn on the easy mode, by witching to the PVE mode.`,
    },
    {
      title: "What can I do with my SRPG tokens?",
      content: `You can use these tokens as an in-game currency as well as for staking`,
    },
  ],
};

const styles = {
  bgColor: "transparent",
  titleTextColor: "#EBEEF2",
  rowTitleColor: "#EBEEF2",
  rowContentColor: "#A5ABB3",
  arrowColor: "#FFFFFF",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingLeft: "30px",
  rowContentPaddingRight: "30px",
  
};
const config = {
  expandIcon: "+",
  collapseIcon: "-",
  openOnload: 0,

};
export default function Faqs() {
  return (
    <div className='container mt-5'>
      <div className='row res-footer'>
        <div className='col-md-12 faq-heading ' data-aos='fade-up'>
          <h1 className=' mb-5'>Frequently Asked Questions</h1>

          <div>
            <Faq data={data} styles={styles} config={config} />
          </div>
        </div>
      </div>
    </div>
  );
}
