/** @format */

import React from "react";

export default function GamePlay() {
  return (
    <div className='container game-play-container' id='GamePlay'>
      <div className='gameplay-text' data-aos='fade-right'>
        <h1>Game play</h1>
        <p>
          The Insurrection is a free-to-play and Play-to-earn strategy battle
          game. <br /> The game is a war zoned revolution centric gameplay, on
          the Binance smart chain.
        </p>
      </div>
      <div className='row'>
        <div className='col-md-4'>
         
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-md-4'>
          <div className='card-bg-img  px-2 aa' data-aos='fade-up'>
            <h1>Metaverse</h1>
            <ul className='card-list-items px-5'>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span> Buy or conquer land in the cities</span>
              </li>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span> Construct or customize buildings</span>
              </li>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span> Meet other players</span>
              </li>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span>Create, trade and upgrade your in-game assets</span>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card-bg-img m-auto px-3 bb' data-aos='fade-down'>
            <h1>PVE</h1>
            <ul className='card-list-items px-20'>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span> Explore the cruel world</span>
              </li>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span> Take part in liberations</span>
              </li>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span> Create and join player guilds</span>
              </li>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span> Play and win missions</span>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card-bg-img  px-3 cc' data-aos='fade-up'>
            <h1>PVP</h1>
            <ul className='card-list-items px-14'>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span> Duel with other players</span>
              </li>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span> Join group battles </span>
              </li>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span> Play in MMP mode</span>
              </li>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span>Fight in different battle modes</span>
              </li>
              <li>
                <img src='assets/ellipse 7.svg' alt='' />{" "}
                <span>Participate in tournaments with your team</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
