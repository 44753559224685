import React from 'react'

export default function RoadMap() {
  return (
    <div className='container roadmap mb-5' id='RoadMap'>
        <div className="row">
            <div className="col-md-12">
              <img src="assets/Roadmaps.svg" alt="" className='roadmap-svg w-100' />
          </div>
          </div>
    </div>
  )
}
