/** @format */

import React from "react";
import GameNFT from "../components/elements/GameNFT";
import GamePlay from "../components/elements/GamePlay";
import Hero from "../components/elements/Hero";
import RoadMap from "../components/elements/RoadMap";
// import Teams from '../components/elements/Teams'
import Tokens from "../components/elements/Tokens";
import VideoSection from "../components/elements/VideoSection";
import Faqs from "../components/utils/Faqs";
import Partners from "../components/elements/Partners";
import ScreenShots from "../components/elements/ScreenShots";
import Story from "../components/elements/Story";

export default function Home() {
  return (
    <React.Fragment>
      <Hero />
      <VideoSection />
      <GamePlay />
      <ScreenShots />
      <GameNFT />
      <Story />
      <Tokens />
      {/* <Teams/> */}
      <RoadMap />
      <Faqs />
      <Partners />
    </React.Fragment>
  );
}
