/** @format */

import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "react-multi-carousel/lib/styles.css";
import Base from "./components/layouts/Base";
import Home from "./pages/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactGA from "react-ga";
import { useEffect } from "react";
ReactGA.initialize("G-SMPCJCMQCK");
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });
  return (
    <>
      <Router>
        <Base>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
          </Switch>
        </Base>
      </Router>
    </>
  );
}

export default App;
