/** @format */

import React from "react";

export default function GameNFT() {
  return (
    <div className='container section-padding' id='NFTs'>
      <div className='row'>
        <div className='col-md-3 arrow-box'>
          <img src='assets/arrow1.png' alt='' />
          <img src='assets/arrow.png' alt='' className='mx-3' />
        </div>
        <div className='col-md-9 game-nft-col m-auto' data-aos='fade-right'>
          <h1>Game NFTs</h1>
          <p>
            The Insurrection is a liberation themed game. Hence, we begin
            justice from our own home by providing full ownership of the in-game
            assets to the players, who have earned it through blood and sweat.
          </p>
          <p>
            The in-game inventory of a player embody the character used to play,
            the ammunition in the game, and conquered land and assets.
          </p>
          <p>
            Every possession in the game is a NFT. All NFTs are made with high
            visuals and concentration to provide the best cinematic experience
            in the game. Some NFTs are 2D avatar images, some are 3D versions
            and others are fully animated 3D NFTs in the game.
          </p>
        </div>
        <div className='row mt-5'>
          <div className='col-md-4 '>
            <div className='nft-bg-img pt-5 px-4 m-auto' data-aos='fade-up'>
              <div className='ntf-level-bg'>
                <p>
                  level: <br /> 99
                </p>
              </div>
              <p>
                Race: <span> Human</span>
              </p>
              <p>
                class: : <span> knight</span>
              </p>
              <p>
                skill: : <span> legendary</span>
              </p>
              {/* <img
                src='https://insurrection.s3.us-west-1.amazonaws.com/assets/IMG3.png'
                alt=''
              /> */}
              <video
                autoPlay='autoplay'
                loop
                muted
                className='h-75  w-100 m-auto'>
                <source
                  src={process.env.REACT_APP_S3_URL + "/7pi2jK4.mp4"}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='nft-card-border m-auto'>
              <div className='row text-center'>
                <div className='col border-text'>
                  <p>Type</p>
                  <span>Playable</span>
                </div>
                <div className='col border-text'>
                  <p>Rarity</p>
                  <span>Legendary</span>
                </div>
                <div className='col border-text'>
                  <p>Price</p>
                  <span>
                    0.8{" "}
                    <img
                      src='assets/bnb.png'
                      width={13}
                      style={{ display: "inline-block" }}
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 '>
            <div className='nft-bg-img pt-5 px-4 m-auto' data-aos='fade-up'>
              <div className='ntf-level-bg'>
                <p>
                  level: <br /> 99
                </p>
              </div>
              <p>
                Race: <span> Knight</span>
              </p>
              <p>
                class: : <span> knight</span>
              </p>
              <p>
                skill: : <span> legendary</span>
              </p>
              {/* <img
                src='https://insurrection.s3.us-west-1.amazonaws.com/assets/IMG3.png'
                alt=''
              /> */}
              <video
                autoPlay='autoplay'
                loop
                muted
                className='h-75  w-100 m-auto'>
                <source
                  src={process.env.REACT_APP_S3_URL + "/JFPwL8I.mp4"}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='nft-card-border m-auto'>
              <div className='row text-center'>
                <div className='col border-text'>
                  <p>Type</p>
                  <span>Playable</span>
                </div>
                <div className='col border-text'>
                  <p>Rarity</p>
                  <span>Legendary</span>
                </div>
                <div className='col border-text'>
                  <p>Price</p>
                  <span>
                    0.6{" "}
                    <img
                      src='assets/bnb.png'
                      width={13}
                      style={{ display: "inline-block" }}
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 '>
            <div className='nft-bg-img pt-5 px-4 m-auto' data-aos='fade-up'>
              <div className='ntf-level-bg'>
                <p>
                  level: <br /> 99
                </p>
              </div>
              <p>
                Race: <span> Bear</span>
              </p>
              <p>
                class: : <span> knight</span>
              </p>
              <p>
                skill: : <span> legendary</span>
              </p>
              {/* <img
                src='https://insurrection.s3.us-west-1.amazonaws.com/assets/IMG.png'
                alt=''
              /> */}
              <video
                autoPlay='autoplay'
                loop
                muted
                className='h-75  w-100 m-auto'>
                <source
                  src={process.env.REACT_APP_S3_URL + "/iqDF1Zw.mp4"}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='nft-card-border m-auto'>
              <div className='row text-center'>
                <div className='col border-text'>
                  <p>Type</p>
                  <span>Playable</span>
                </div>
                <div className='col border-text'>
                  <p>Rarity</p>
                  <span>Legendary</span>
                </div>
                <div className='col border-text'>
                  <p>Price</p>
                  <span>
                    0.3{" "}
                    <img
                      src='assets/bnb.png'
                      width={13}
                      style={{ display: "inline-block" }}
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 mt-5'>
            <div className='nft-bg-img pt-5 px-4 m-auto' data-aos='fade-up'>
              <div className='ntf-level-bg'>
                <p>
                  level: <br /> 99
                </p>
              </div>
              <p>
                Race: <span> Boar</span>
              </p>
              <p>
                class: : <span> knight</span>
              </p>
              <p>
                skill: : <span> legendary</span>
              </p>
              {/* <img
                src='https://insurrection.s3.us-west-1.amazonaws.com/assets/nft-img.png'
                alt=''
              /> */}
              <video
                autoPlay='autoplay'
                loop
                muted
                className='h-75  w-100 m-auto'>
                <source
                  src={process.env.REACT_APP_S3_URL + "/Zn1Z2nV.mp4"}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='nft-card-border m-auto'>
              <div className='row text-center'>
                <div className='col border-text'>
                  <p>Type</p>
                  <span>Playable</span>
                </div>
                <div className='col border-text'>
                  <p>Rarity</p>
                  <span>Legendary</span>
                </div>
                <div className='col border-text'>
                  <p>Price</p>
                  <span>
                    0.3{" "}
                    <img
                      src='assets/bnb.png'
                      width={13}
                      style={{ display: "inline-block" }}
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 mt-5'>
            <div className='nft-bg-img pt-5 px-4 m-auto' data-aos='fade-up'>
              <div className='ntf-level-bg'>
                <p>
                  level: <br /> 99
                </p>
              </div>
              <p>
                Race: <span> Goat</span>
              </p>
              <p>
                class: : <span> knight</span>
              </p>
              <p>
                skill: : <span> legendary</span>
              </p>
              {/* <img
                src='https://insurrection.s3.us-west-1.amazonaws.com/assets/IMG3.png'
                alt=''
              /> */}
              <video
                autoPlay='autoplay'
                loop
                muted
                className='h-75  w-100 m-auto'>
                <source
                  src={process.env.REACT_APP_S3_URL + "/OZlCjLh.mp4"}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='nft-card-border m-auto'>
              <div className='row text-center'>
                <div className='col border-text'>
                  <p>Type</p>
                  <span>Playable</span>
                </div>
                <div className='col border-text'>
                  <p>Rarity</p>
                  <span>Legendary</span>
                </div>
                <div className='col border-text'>
                  <p>Price</p>
                  <span>
                    0.2{" "}
                    <img
                      src='assets/bnb.png'
                      width={13}
                      style={{ display: "inline-block" }}
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 mt-5'>
            <div className='nft-bg-img pt-5 px-4 m-auto' data-aos='fade-up'>
              <div className='ntf-level-bg'>
                <p>
                  level: <br /> 99
                </p>
              </div>
              <p>
                Race: <span> Tiger</span>
              </p>
              <p>
                class: : <span> knight</span>
              </p>
              <p>
                skill: : <span> legendary</span>
              </p>
              {/* <img
                src='https://insurrection.s3.us-west-1.amazonaws.com/assets/IMG3.png'
                alt=''
              /> */}
              <video
                autoPlay='autoplay'
                loop
                muted
                className='h-75  w-100 m-auto'>
                <source
                  src={process.env.REACT_APP_S3_URL + "/IDNuGyt.mp4"}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='nft-card-border m-auto'>
              <div className='row text-center'>
                <div className='col border-text'>
                  <p>Type</p>
                  <span>Playable</span>
                </div>
                <div className='col border-text'>
                  <p>Rarity</p>
                  <span>Legendary</span>
                </div>
                <div className='col border-text'>
                  <p>Price</p>
                  <span>
                    0.5{" "}
                    <img
                      src='assets/bnb.png'
                      width={13}
                      style={{ display: "inline-block" }}
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 mt-5'>
            <div className='nft-bg-img pt-5 px-4 m-auto' data-aos='fade-up'>
              <div className='ntf-level-bg'>
                <p>
                  level: <br /> 99
                </p>
              </div>
              <p>
                Race: <span> Warlock</span>
              </p>
              <p>
                class: : <span> knight</span>
              </p>
              <p>
                skill: : <span> legendary</span>
              </p>
              {/* <img
                src='https://insurrection.s3.us-west-1.amazonaws.com/assets/IMG3.png'
                alt=''
              /> */}
              <video
                autoPlay='autoplay'
                loop
                muted
                className='h-75  w-100 m-auto'>
                <source
                  src={process.env.REACT_APP_S3_URL + "/PnH874d.mp4"}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='nft-card-border m-auto'>
              <div className='row text-center'>
                <div className='col border-text'>
                  <p>Type</p>
                  <span>Playable</span>
                </div>
                <div className='col border-text'>
                  <p>Rarity</p>
                  <span>Legendary</span>
                </div>
                <div className='col border-text'>
                  <p>Price</p>
                  <span>
                    0.4{" "}
                    <img
                      src='assets/bnb.png'
                      width={13}
                      style={{ display: "inline-block" }}
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 mt-5'>
            <div className='nft-bg-img pt-5 px-4 m-auto' data-aos='fade-up'>
              <div className='ntf-level-bg'>
                <p>
                  level: <br /> 99
                </p>
              </div>
              <p>
                Race: <span> Warrior</span>
              </p>
              <p>
                class: : <span> knight</span>
              </p>
              <p>
                skill: : <span> legendary</span>
              </p>
              {/* <img
                src='https://insurrection.s3.us-west-1.amazonaws.com/assets/IMG3.png'
                alt=''
              /> */}
              <video
                autoPlay='autoplay'
                loop
                muted
                className='h-75  w-100 m-auto'>
                <source
                  src={process.env.REACT_APP_S3_URL + "/HWBeLox.mp4"}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='nft-card-border m-auto'>
              <div className='row text-center'>
                <div className='col border-text'>
                  <p>Type</p>
                  <span>Playable</span>
                </div>
                <div className='col border-text'>
                  <p>Rarity</p>
                  <span>Legendary</span>
                </div>
                <div className='col border-text'>
                  <p>Price</p>
                  <span>
                    0.2{" "}
                    <img
                      src='assets/bnb.png'
                      width={13}
                      style={{ display: "inline-block" }}
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 mt-5'>
            <div className='nft-bg-img pt-5 px-4 m-auto' data-aos='fade-up'>
              <div className='ntf-level-bg'>
                <p>
                  level: <br /> 99
                </p>
              </div>
              <p>
                Race: <span> Wolf</span>
              </p>
              <p>
                class: : <span> knight</span>
              </p>
              <p>
                skill: : <span> legendary</span>
              </p>
              {/* <img
                src='https://insurrection.s3.us-west-1.amazonaws.com/assets/IMG3.png'
                alt=''
              /> */}
              <video
                autoPlay='autoplay'
                loop
                muted
                className='h-75  w-100 m-auto'>
                <source
                  src={process.env.REACT_APP_S3_URL + "/XGye5yI.mp4"}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='nft-card-border m-auto'>
              <div className='row text-center'>
                <div className='col border-text'>
                  <p>Type</p>
                  <span>Playable</span>
                </div>
                <div className='col border-text'>
                  <p>Rarity</p>
                  <span>Legendary</span>
                </div>
                <div className='col border-text'>
                  <p>Price</p>
                  <span>
                    0.2{" "}
                    <img
                      src='assets/bnb.png'
                      width={13}
                      style={{ display: "inline-block" }}
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
