/** @format */

import React from "react";
import {
  FaGithub,
  FaDiscord,
  FaReddit,
  FaTwitter,
  FaTelegram,
  FaMediumM,
  FaFacebook,
} from "react-icons/fa";

export default function Footer() {
  return (
    <>
      <footer>
        <div className='container-fluid' data-aos='fade-up'>
          <div className='container'>
            <div className='row'>
              <div className='text-center mt-3'>
                <img
                  src='assets/logo-hr.png'
                  alt=''
                  className='footer-logo m-auto'
                  width={300}
                />
              </div>
              <hr className=' mt-3' />
              <div className='col-md-6 mt-5'>
                <p className='footer-text'>
                  The Insurrection is a free-to-play and Play-to-earn <br />{" "}
                  strategy battle game. The game is a war zoned <br />{" "}
                  revolution centric gameplay, on the Binance smart chain.
                </p>
                <p>Our Community</p>
                <div className='flex'>
                  <a
                    href='https://insurrectionapp.medium.com/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <FaMediumM className='footer-social-icon mx-2' size={20} />
                  </a>
                  <a
                    href='https://twitter.com/insurrectionapp'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <FaTwitter className='footer-social-icon mx-2' size={20} />
                  </a>
                  <a
                    href='https://t.me/insurrectionapp'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <FaTelegram className='footer-social-icon mx-2' size={20} />
                  </a>
                  <a
                    href='https://www.reddit.com/r/insurrectionapp/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <FaReddit className='footer-social-icon mx-2' size={20} />
                  </a>
                  <a
                    href='https://github.com/insurrectionapp'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <FaGithub className='footer-social-icon mx-2' size={20} />
                  </a>
                  <a
                    href='https://discord.gg/bQjEbxph'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <FaDiscord className='footer-social-icon mx-2' size={20} />
                  </a>
                  <a
                    href='https://www.facebook.com/insurrectionapp'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <FaFacebook className='footer-social-icon mx-2' size={20} />
                  </a>
                </div>
              </div>
              <div className='col-md-3 mt-5 col-6'>
                {/* <ul className='footer-list'>
                  <li>
                    <a href='#in-progress'>Staking</a>
                  </li>
                  <li>
                    <a href='#in-progress'>Vote</a>
                  </li>
                  <li>
                    <a href='#in-progress'>Play Game</a>
                  </li>
                </ul> */}
              </div>
              <div className='col-md-3 mt-5 col-6'>
                <ul className='footer-list'>
                  <li>
                    <a href='#GamePlay'>Gameplay</a>
                  </li>
                  <li>
                    <a href='#RoadMap'>Roadmap</a>
                  </li>
                  <li>
                    <a href='#Tokkens'>Tokenomics</a>
                  </li>
                </ul>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <p className='footer-bottom-text'>
                    Insurrection 2024 © All right reserved
                  </p>
                </div>
                <div className='col-md-6 text-end'>
                  <div className='flex justify-end'>
                    <img
                      src='assets/mobile app.svg'
                      alt=''
                      className='mx-4 mt-3'
                    />
                    <img
                      src='assets/mobile app1.svg'
                      alt=''
                      className='mx-4 mt-3'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
